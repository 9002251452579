body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #021B55;
    margin-bottom: 26px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container .site-page {
    background-color: white;
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
}

.site-bread-crumb::after {
    padding-left: 4px;
    padding-right: 4px;
    content: '|';
    color: #6c757d;
}
