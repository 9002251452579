.match-winner .match-team-name,
.match-winner .match-team-score {
    font-weight: bold;
}

.site-page .card:not(:last-child) {
    margin-bottom: 16px;
}

.card-toggle {
    cursor: pointer;
}
.card-toggle .fa {
    transition: transform 100ms;
}
.card-collapse .fa {
    transform: rotate(-90deg);
}
.card-body-collapse {
    display: none;
}

.card .match-container .match-row:first-child .col {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.card .match-container .last-match-row .col.match-list-collapse {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
/*
.match:hover {
    background-color: lightgrey;
}
*/
@media (min-width: 576px) {
    .container .match, 
    .container-sm .match {
        max-width: 442px;
    }
    .card .match-container .match-col:last-child .match {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}

@media (min-width: 768px) {
    .container .match,
    .container-md .match,
    .container-sm .match {
        max-width: 622px;
        min-width: 622px;
    }
    .card .match-container .match-col:last-child .match {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}

@media (min-width: 992px) {
    .container .match,
    .container-lg .match,
    .container-md .match,
    .container-sm .match {
        max-width: 431px;
        min-width: 431px;
    }
    .card .match-container .match-col:nth-last-child(2) .match {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 6px;
    }
    .card .match-container .match-col:last-child .match {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 0px;
    }
}

@media (min-width: 1200px) {
    .container .match,
    .container-lg .match,
    .container-md .match,
    .container-sm .match,
    .container-xl .match {
        max-width: 521px;
        min-width: 521px;
    }
    .card .match-container .match-col:nth-last-child(2) .match {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 6px;
    }
    .card .match-container .match-col:last-child .match {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 0px;
    }
}

@media (min-width: 1400px) {
    .container .match,
    .container-lg .match,
    .container-md .match,
    .container-sm .match,
    .container-xl .match,
    .container-xxl .match {
        max-width: 611px;
        min-width: 611px;
    }
    .card .match-container .match-col:nth-last-child(2) .match {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 6px;
    }
    .card .match-container .match-col:last-child .match {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 0px;
    }
}
