nav.navbar .navbar-collapse.collapse.show {
    background-color: #FFFFFF;
    z-index: 1000;
}

.navbar-brand-icon {
    height: 40px;
    width: 40px;
}
/*
nav.navbar .navbar-collapse.collapse.show .navbar-nav {
    padding: 15px;
}
*/