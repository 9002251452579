.match-container .match-row:first-child .col {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.match-container .last-match-row .col.match-list-collapse {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
/*
.match:hover {
    background-color: lightgrey;
}
*/
@media (min-width: 576px) {
    .container .team-match-container .match,
    .container-sm .team-match-container .match {
        max-width: 476px;
    }

    .team-match-container .match-container .match-col:last-child .match {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}

@media (min-width: 768px) {
    .container .team-match-container .match,
    .container-md .team-match-container .match,
    .container-sm .team-match-container .match {
        max-width: 656px;
        min-width: 656px;
    }

    .team-match-container .match-container .match-col:last-child .match {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}

@media (min-width: 992px) {
    .container .team-match-container .match,
    .container-lg .team-match-container .match,
    .container-md .team-match-container .match,
    .container-sm .team-match-container .match {
        max-width: 448px;
        min-width: 448px;
    }

    .team-match-container .match-container .match-col:nth-last-child(2) .match {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 6px;
    }

    .team-match-container .match-container .match-col:last-child .match {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 0px;
    }
}

@media (min-width: 1200px) {
    .container .team-match-container .match,
    .container-lg .team-match-container .match,
    .container-md .team-match-container .match,
    .container-sm .team-match-container .match,
    .container-xl .team-match-container .match {
        max-width: 538px;
        min-width: 538px;
    }

    .team-match-container .match-container .match-col:nth-last-child(2) .match {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 6px;
    }

    .team-match-container .match-container .match-col:last-child .match {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 0px;
    }
}

@media (min-width: 1400px) {
    .container .team-match-container .match,
    .container-lg .team-match-container .match,
    .container-md .team-match-container .match,
    .container-sm .team-match-container .match,
    .container-xl .team-match-container .match,
    .container-xxl .team-match-container .match {
        max-width: 628px;
        min-width: 628px;
    }

    .team-match-container .match-container .match-col:nth-last-child(2) .match {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 6px;
    }

    .team-match-container .match-container .match-col:last-child .match {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 0px;
    }
}
