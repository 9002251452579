.match-winner .match-team-name,
.match-winner .match-team-score {
    font-weight: bold;
}

.match:hover {
    background-color: lightgrey;
    /*cursor: pointer;*/
}

/*
@media (min-width: 576px) {
    .container .match,
    .container-sm .match {
        max-width: 576px;
    }
}

@media (min-width: 768px) {
    .container .match,
    .container-md .match,
    .container-sm .match {
        max-width: 656px;
        min-width: 656px;
    }
}

@media (min-width: 992px) {
    .container .match,
    .container-lg .match,
    .container-md .match,
    .container-sm .match {
        max-width: 448px;
        min-width: 448px;
    }
}

@media (min-width: 1200px) {
    .container .match,
    .container-lg .match,
    .container-md .match,
    .container-sm .match,
    .container-xl .match {
        max-width: 538px;
        min-width: 538px;
    }
}

@media (min-width: 1400px) {
    .container .match,
    .container-lg .match,
    .container-md .match,
    .container-sm .match,
    .container-xl .match,
    .container-xxl .match {
        max-width: 628px;
        min-width: 628px;
    }
}
*/