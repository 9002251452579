.match-list-toggle {
    cursor: pointer;
}
.match-list-toggle .fa {
    transition: transform 100ms;
}
.match-list-collapse .fa {
    transform: rotate(-90deg);
}

.match-col.match-col-collapse {
    max-height: 0;
    transition: max-height 100ms ease-out;
    overflow: hidden;
}
.match-col {
    max-height: 450px;
    transition: max-height 100ms ease-in;
}
